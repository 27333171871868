import React from 'react';

const Dashboard = () => {
    return (
        <div className="container">

            <div className="page-section">
                <h1 className="text-display-1">Dashboard</h1>
            </div>

            <div className="row" data-toggle="isotope">
                <div className="item col-xs-12 col-lg-6">
                    <div className="panel panel-default paper-shadow" data-z="0.5">
                        <div className="panel-heading">
                            <div className="media v-middle">
                                <div className="media-body">
                                    <h4 className="text-headline margin-none">Earnings</h4>
                                    <p className="text-subhead text-light">This Month</p>
                                </div>
                                <div className="media-right">
                                    <a className="btn btn-white btn-flat" href="app-instructor-earnings.html">Reports</a>
                                </div>
                            </div>
                        </div>
                        <div className="panel-body">
                            <div id="line-holder" data-toggle="flot-chart-earnings" className="flotchart-holder height-200"></div>
                        </div>
                        <hr />
                        <div className="panel-body">
                            <div className="row text-center">
                                <div className="col-md-6">
                                    <h4 className="margin-none">Gross Revenue</h4>
                                    <p className="text-display-1 text-warning margin-none">102.4k</p>
                                </div>
                                <div className="col-md-6">
                                    <h4 className="margin-none">Net Revenue</h4>
                                    <p className="text-display-1 text-success margin-none">55k</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item col-xs-12 col-lg-6">
                    <div className="panel panel-default paper-shadow" data-z="0.5">
                        <div className="panel-heading">
                            <h4 className="text-headline margin-none">My Courses</h4>
                            <p className="text-subhead text-light">Your recent courses</p>
                        </div>
                        <ul className="list-group">
                            <li className="list-group-item media v-middle">
                                <div className="media-body">
                                    <a href="app-instructor-course-edit-course.html" className="text-subhead list-group-link">Basics of HTML</a>
                                </div>
                                <div className="media-right">
                                    <div className="progress progress-mini width-100 margin-none">
                                        <div className="progress-bar progress-bar-green-300" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style={{ width: "45%" }}>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item media v-middle">
                                <div className="media-body">
                                    <a href="app-instructor-course-edit-course.html" className="text-subhead list-group-link">Angular in Steps</a>
                                </div>
                                <div className="media-right">
                                    <div className="progress progress-mini width-100 margin-none">
                                        <div className="progress-bar progress-bar-green-300" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item media v-middle">
                                <div className="media-body">
                                    <a href="app-instructor-course-edit-course.html" className="text-subhead list-group-link">Bootstrap Foundations</a>
                                </div>
                                <div className="media-right">
                                    <div className="progress progress-mini width-100 margin-none">
                                        <div className="progress-bar progress-bar-green-300" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: "25%" }}>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="panel-footer text-right">
                            <a href="app-instructor-courses.html" className="btn btn-white paper-shadow relative" data-z="0" data-hover-z="1" data-animated="">View all</a>
                            <a href="app-instructor-course-edit-course.html" className="btn btn-primary paper-shadow relative" data-z="0" data-hover-z="1" data-animated="">CREATE COURSE <i className="fa fa-plus"></i></a>
                        </div>
                    </div>
                </div>
                <div className="item col-xs-12 col-lg-6">
                    <div className="s-container">
                        <h4 className="text-headline margin-none">Comments</h4>
                        <p className="text-subhead text-light">Latest student comments </p>
                    </div>
                    <div className="panel panel-default">
                        <ul className="list-group">
                            <li className="list-group-item">
                                <div className="media v-middle margin-v-0-10">
                                    <div className="media-body">
                                        <p className="text-subhead">
                                            <a href="#">
                                                <img src="images\people\110\guy-1.jpg" alt="person" className="width-30 img-circle" />
                                            </a> &nbsp;
                                            <a href="#">mosaicpro</a>
                                            <span className="text-caption text-light">24 min ago</span>
                                        </p>
                                    </div>
                                    <div className="media-right">
                                        <div className="width-50 text-right">
                                            <a href="#" className="btn btn-white btn-xs"><i className="fa fa-reply"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias, blanditiis eligendi expedita ipsam minus non numquam quidem reiciendis ut?</p>
                                <p className="text-light"><span className="caption">Course:</span> <a href="app-student-course.html">Basics Of HTML</a></p>
                            </li>
                            <li className="list-group-item">
                                <div className="media v-middle margin-v-0-10">
                                    <div className="media-body">
                                        <p className="text-subhead">
                                            <a href="#">
                                                <img src="images\people\110\guy-2.jpg" alt="person" className="width-30 img-circle" />
                                            </a> &nbsp;
                                            <a href="#">mosaicpro</a>
                                            <span className="text-caption text-light">60 min ago</span>
                                        </p>
                                    </div>
                                    <div className="media-right">
                                        <div className="width-50 text-right">
                                            <a href="#" className="btn btn-white btn-xs"><i className="fa fa-reply"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias, blanditiis eligendi expedita ipsam minus non numquam quidem reiciendis ut?</p>
                                <p className="text-light"><span className="caption">Course:</span> <a href="app-student-course.html">Basics Of HTML</a></p>
                            </li>
                            <li className="list-group-item">
                                <div className="media v-middle margin-v-0-10">
                                    <div className="media-body">
                                        <p className="text-subhead">
                                            <a href="#">
                                                <img src="images\people\110\guy-5.jpg" alt="person" className="width-30 img-circle" />
                                            </a> &nbsp;
                                            <a href="#">mosaicpro</a>
                                            <span className="text-caption text-light">1 min ago</span>
                                        </p>
                                    </div>
                                    <div className="media-right">
                                        <div className="width-50 text-right">
                                            <a href="#" className="btn btn-white btn-xs"><i className="fa fa-reply"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias, blanditiis eligendi expedita ipsam minus non numquam quidem reiciendis ut?</p>
                                <p className="text-light"><span className="caption">Course:</span> <a href="app-student-course.html">Basics Of HTML</a></p>
                            </li>
                            <li className="list-group-item">
                                <div className="media v-middle margin-v-0-10">
                                    <div className="media-body">
                                        <p className="text-subhead">
                                            <a href="#">
                                                <img src="images\people\110\guy-1.jpg" alt="person" className="width-30 img-circle" />
                                            </a> &nbsp;
                                            <a href="#">mosaicpro</a>
                                            <span className="text-caption text-light">47 min ago</span>
                                        </p>
                                    </div>
                                    <div className="media-right">
                                        <div className="width-50 text-right">
                                            <a href="#" className="btn btn-white btn-xs"><i className="fa fa-reply"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias, blanditiis eligendi expedita ipsam minus non numquam quidem reiciendis ut?</p>
                                <p className="text-light"><span className="caption">Course:</span> <a href="app-student-course.html">Basics Of HTML</a></p>
                            </li>
                            <li className="list-group-item">
                                <div className="media v-middle margin-v-0-10">
                                    <div className="media-body">
                                        <p className="text-subhead">
                                            <a href="#">
                                                <img src="images\people\110\guy-2.jpg" alt="person" className="width-30 img-circle" />
                                            </a> &nbsp;
                                            <a href="#">mosaicpro</a>
                                            <span className="text-caption text-light">19 min ago</span>
                                        </p>
                                    </div>
                                    <div className="media-right">
                                        <div className="width-50 text-right">
                                            <a href="#" className="btn btn-white btn-xs"><i className="fa fa-reply"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium alias, blanditiis eligendi expedita ipsam minus non numquam quidem reiciendis ut?</p>
                                <p className="text-light"><span className="caption">Course:</span> <a href="app-student-course.html">Basics Of HTML</a></p>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="item col-xs-12 col-lg-6">
                    <div className="panel panel-default paper-shadow" data-z="0.5">
                        <div className="panel-heading">
                            <div className="media v-middle">
                                <div className="media-body">
                                    <h4 className="text-headline margin-none">Transactions</h4>
                                    <p className="text-subhead text-light">Latest from statement</p>
                                </div>
                                <div className="media-right">
                                    <a className="btn btn-white btn-flat" href="app-instructor-statement.html">Statement</a>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table text-subhead v-middle">
                                <tbody>
                                    <tr>
                                        <td className="width-100 text-caption">
                                            <div className="label label-grey-200 label-xs">12 Jan 2015</div>
                                        </td>
                                        <td>Adrian Demian</td>
                                        <td className="width-80 text-center"><a href="#">#9936</a></td>
                                        <td className="width-50 text-center">&dollar;28</td>
                                    </tr>
                                    <tr>
                                        <td className="width-100 text-caption">
                                            <div className="label label-grey-200 label-xs">12 Jan 2015</div>
                                        </td>
                                        <td>Adrian Demian</td>
                                        <td className="width-80 text-center"><a href="#">#880</a></td>
                                        <td className="width-50 text-center">&dollar;60</td>
                                    </tr>
                                    <tr>
                                        <td className="width-100 text-caption">
                                            <div className="label label-grey-200 label-xs">12 Jan 2015</div>
                                        </td>
                                        <td>Adrian Demian</td>
                                        <td className="width-80 text-center"><a href="#">#1758</a></td>
                                        <td className="width-50 text-center">&dollar;54</td>
                                    </tr>
                                    <tr>
                                        <td className="width-100 text-caption">
                                            <div className="label label-grey-200 label-xs">12 Jan 2015</div>
                                        </td>
                                        <td>Adrian Demian</td>
                                        <td className="width-80 text-center"><a href="#">#7983</a></td>
                                        <td className="width-50 text-center">&dollar;97</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Dashboard;