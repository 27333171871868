import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Provider } from '../../Context/Appcontext';

const Sidebar = () => {
    const context = useContext(Provider);
    return (
        <>
        <div className="sidebar sidebar-left sidebar-size-3 sidebar-offset-0 sidebar-visible-desktop sidebar-visible-mobile sidebar-skin-dark " id="sidebar-menu" data-type="collapse">
                <div data-scrollable tabIndex={0} style={{
                    overflowY: "scroll",
                    maxHeight: "calc(100vh - 120px)",
                    outline: "none",
                }} >

                {/* <div className="sidebar-block">
                    <div className="profile">
                        <a href="#">
                            <img src="images\people\110\guy-6.jpg" alt="people" className="img-circle width-80" />
                        </a>
                        <h4 className="text-display-1 margin-none">Instructor Name</h4>
                    </div>
                </div> */}

                <ul className="sidebar-menu ">
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet">
                           <i className="fa fa-home"></i><span>Dashboard</span>
                        </Link>
                    </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/DietDistrictlist">
                            <i className="fa fa-mortar-board"></i><span>College List</span>
                        </Link>
                    </li>
                    {/* ---------School Insert Update and list----------- */}
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/InsertDietSchool">
                            <i className="fa fa-mortar-board"></i><span>Insert New School</span>
                        </Link>
                    </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/ListDietSchool">
                            <i className="fa fa-mortar-board"></i><span>All Schools List</span>
                        </Link>
                    </li>
                        <li className="active my-5" style={{ margin: (0, 5) }}>
                            <Link to="/diet/SearchAllSchool">
                                <i className="fa fa-mortar-board"></i><span>Search School</span>
                            </Link>
                        </li>
                    {/* -------------------- */}
                    











                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/DietSchoolDetails">
                            <i className="fa fa-mortar-board"></i><span>School Details</span>
                        </Link>
                    </li>
                        <li className="active my-5" style={{ margin: (0, 5) }}>
                            <Link to="/diet/ChangeAllotments">
                                <i className="fa fa-mortar-board"></i><span>Allotment Change</span>
                            </Link>
                        </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/CollageListchoice">
                            <i className="fa fa-mortar-board"></i><span>Student Pending Choice</span>
                        </Link>
                    </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/CollageListorientation">
                            <i className="fa fa-mortar-board"></i><span>Student Orientation</span>
                        </Link>
                    </li>
                        
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/SchoolAttendencellist">
                            <i className="fa fa-mortar-board"></i><span>School Attedence Check</span>
                        </Link>
                    </li>
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/SchoolList">
                            <i className="fa fa-mortar-board"></i><span>SchoolList(Diet)</span>
                        </Link>
                    </li>
                    {/* <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/SchoolListDetail">
                            <i className="fa fa-mortar-board"></i><span>School Detail Update</span>
                        </Link>
                    </li> */}
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/CollageListAssessment">
                            <i className="fa fa-mortar-board"></i><span>Student Assessment</span>
                        </Link>
                    </li>
                  
                    <li className="active my-5" style={{ margin: (0, 5) }}>
                        <Link to="/diet/PasswordChnage">
                            <i className="fa fa-mortar-board"></i><span>Change Password</span>
                        </Link>
                    </li>
                  
                 
                    <li className="active my-5" style={{ margin: (0, 5) }} onClick={() => context.logoutFunc()}>

                        <a><i className="fa fa-sign-out"></i><span>Log Out</span></a>

                    </li>
                    {/* <button className='btn btn-primary' onClick={() => context.logoutFunc()}>Logout</button> */}
                </ul>
            </div>
        </div>
        </>
    );
};

export default Sidebar;