import React, { Component } from "react";
import BaseApi from "../../../API/BaseApi";
import { toast } from "react-toastify";

class SearchAllSchool extends Component {
  state = {
    code: "",
    searchData: "",
    error: null,
  };
  handleCodeChange = (event) => {
    this.setState({ code: event.target.value });
  };

  url = () => {
    BaseApi.get(`School/SchoolList?Code=${this.state.code}`).then((resp) => {
      console.log(resp.data);
      this.setState({ searchData: resp.data, error: null });
    });
  };

  searchSchool = () => {
    const { code } = this.state;
    if (!code.trim()) {
      toast.error("Please input a value for search", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    this.url();
  };

  render() {
    const { searchData, error } = this.state;
    return (
      <div className="page-section">
        <h1 className="text-center mb-4">School Search</h1>
        <div className="row">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Code"
              value={this.state.code}
              onChange={this.handleCodeChange}
              required
            />
          </div>
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="button"
              onClick={this.searchSchool}
            >
              Search
            </button>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}

        {searchData && (
          <div className="mt-4">
            <h2 className="mb-3">Search Results</h2>
            <table className="table table-bordered responsive">
              <thead>
                <tr>
                  <th>Code</th>
                  <th>School Name</th>
                  <th>Location</th>
                  <th>Address</th>
                  <th>EmailID</th>
                  <th>MobileNo</th>
                </tr>
              </thead>
              <tbody>
                {searchData.map((school) => (
                  <tr key={school.schoolId}>
                    <td>{school.code}</td>
                    <td>{school.schoolName}</td>
                    <td>{school.locationName}</td>
                    <td>{school.address1}</td>
                    <td>{school.emailId}</td>
                    <td>{school.mobileNo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default SearchAllSchool;
