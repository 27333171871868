import React, { useState, useEffect, useContext } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MasterTypeApi from "../../../API/MasterTypeApi";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Provider } from "../../../Context/Appcontext";
import DistrictschoolApi from "../../../API/DistrictschoolApi";

const ChangeAllotments = () => {
  const [Data, setData] = useState([]);
  const [schoolid, setselectedschool] = useState();
  const [blog, setBlog] = useState([]);
  const [school, setschool] = useState([]);
  const [District, setDistrict] = useState([]);
  const contextNew = useContext(Provider);
  useEffect(() => {
    handleDistrict();
    console.log("test", school);
  }, []);

  const { id } = useParams();
  const notify = () => toast.success("Sucessfully Changed!");
  const errormsg = (Data) =>
    toast.error(Data, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  //   get data function
  const Getdata = (values, resetForm) => {
    MasterTypeApi.GetstudentDetailsbyrollnoNew(
      values.Code,
      contextNew.user
    ).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setData(Data);
        resetForm();
        console.log("Student  dtails........===>", Data);
        // console.log('hi sunny',context.UserDetail.locationId)
      }
    });
  };

  const changeBlock = (id) => {
    // console.log('sunny',id)
    DistrictschoolApi.Block(id).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setBlog(resp.data);
      }
    });
  };

  //   school list
  const changeschool = (id) => {
    console.log("sunny", id);
    DistrictschoolApi.LocationSchool(id).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setschool(resp.data);
        console.log("scholdata ", resp.data);
      }
    });
  };

  // District Dropdown
  const handleDistrict = () => {
    DistrictschoolApi.GetDistrict().then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setDistrict(resp.data);
        console.log("district1111 ", resp.data);
      }
    });
  };

  //   post data function
  const navigate = useNavigate();
  const ChangeallotSchool = (resetForm) => {
    console.log("helo", Data.studentId, schoolid);

    DistrictschoolApi.Changeschool(Data?.studentId, schoolid)

      .then((resp) => {
        // console.log("id",id)

        if (resp.ok) {
          let Data = resp?.data;
          if (Data == "Updated Successfully !") {
            // Getdata();

            notify();
            navigate("/diet/ChangeAllotments");
            window.location.reload(false);
            resetForm();
            // setData(null);
            // setschool(null);
            // Getdata();
          } else {
            // resetForm();
            // Getdata();
            errormsg(Data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="card border-warning mb-3">
        <div className="card-header bg-info text-white">New Permission</div>
        {/* <div className="card-title text-danger ml-3 mt-3">All Fields Are Mandatory *</div> */}
        <div className="card-body ">
          <Formik
            initialValues={{
              Code: "",
            }}
            onSubmit={(values, { resetForm }) => {
              // same shape as initial values
              // isPost ? postData(values, resetForm) : putData(values, resetForm);
              Getdata(values, resetForm);
            }}
            // validationSchema={validationSchema}
          >
            {({ errors, touched, values, handleChange, handleBlur }) => (
              <Form>
                <div className="row">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="Code">Roll Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Roll Number"
                          name="Code"
                          id="Code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Code}
                        />
                        {/* {errors.Name && touched.Name ? (
                                                <div style={{ color: "red" }}>{errors.Name}</div>
                                            ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                <button type="submit" className="btn btn-primary ">
                  {" "}
                  Search Student{" "}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <br></br>

      <div className="table-responsive">
        <table className="table table-hover table-outline  mb-0 ">
          <thead>
            <tr>
              <th className="text-center">Name</th>
              <th className="text-center">Roll Number</th>
              <th className="text-center">Mobile Number</th>
              <th className="text-center">College Name</th>
              <th className="text-center">Class Name</th>
              {/* <th className="text-center">Contact Number</th> */}
              <th className="text-center">Aloted School</th>

              <th className="text-center">change</th>

              {/* <th className="text-center">Attendence </th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* <td className="text-center">{i + 1}</td> */}
              <td className="text-center">{Data.studentName}</td>
              <td className="text-center">{Data.rollNo}</td>
              <td className="text-center">{Data.mobileNo}</td>
              <td className="text-center">{Data.collegeName}</td>
              <td className="text-center">{Data.className}</td>
              {/* <td className="text-center">{Data.mobileNo}</td> */}
              <td className="text-center">{Data.alloatedSchool}</td>
              <td className="text-center">
                <button
                  disabled={schoolid == null ? true : false}
                  className="btn btn-danger  mr-3"
                  style={{ float: "right" }}
                  onClick={() => ChangeallotSchool(Data.studentId)}
                >
                  Change School
                </button>

                {/* <td className="text-center">   <button className="btn btn-success  mr-3" style={{ float: 'right', }}
                                        onClick={() => DeltemasterType(data.id)}
                                    >Update</button></td> */}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="col sm-4">
          <center>
            <select
              style={{
                // borderRadius:"45%",
                width: "60%",
                paddingBlock: "10px",
                color: "#27AE60",
                border: "solid 2px #27AE60",
              }}
              // onChange={(e) => handleState(e.target.value)}

              onChange={(e) => changeBlock(e.target.value)}
              // onChange={(e) => { changeState(e) }}
            >
              <option selected>Open this select District</option>
              {District?.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))}
            </select>
          </center>
        </div>
        <br></br>
        <div className="col sm-11 justify-content-md-center">
          <center>
            <select
              style={{
                // borderRadius:"45%",
                width: "60%",
                paddingBlock: "10px",
                color: "#27AE60",
                border: "solid 2px #27AE60",
              }}
              onChange={(e) => changeschool(e.target.value)}
            >
              <option selected>Open this select Block</option>
              {blog?.map((item) => (
                <>
                  <option value={item.id}>{item.name}</option>
                </>
              ))}
            </select>
          </center>
        </div>
        <br></br>
        <div className="col sm-11 justify-content-md-center">
          <center>
            <select
              style={{
                // borderRadius:"45%",
                width: "60%",
                paddingBlock: "10px",
                color: "#27AE60",
                border: "solid 2px #27AE60",
              }}
              onChange={(e) => setselectedschool(e.target.value)}
            >
              <option selected>Open this select School</option>
              {school?.map((item) => (
                <>
                  <option value={item.schoolId}>
                    {item.name}({item.schoolType}) s.code={item.schoolCode}
                    (Totalseat={item.totalTrainingSeat})(Fillseat=
                    {item.filledSeat})
                  </option>
                </>
              ))}
            </select>
          </center>
        </div>
      </div>
      <div>
        <br />
        <br />
      </div>
    </>
  );
};

export default ChangeAllotments;
