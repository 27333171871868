import logo from './logo.svg';
import './App.css';
import Layout from './Components/Layout/Layout';
import { Routes, Route } from 'react-router-dom';
import Login from './Components/Screens/Login';
import { Provider } from './Context/Appcontext';
import React, { useContext } from 'react';
import SchoolDashboard from './Components/Screens/School/SchoolDashboard';
import SchoolStudentlist from './Components/Screens/School/SchoolStudentlist';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LayoutDiet from './Components/LayoutDiet/LayoutDiet';
import DietDashboard from './Components/Screens/Diet/DietDashboard';
import Schoollist from './Components/Screens/Diet/DietSchoollist';
import DietSchoolStudent from './Components/Screens/Diet/DietSchoolStudent';
import SchoolStudentAttendence from './Components/Screens/School/StudentAttendence';
import DietAttendncecheck from './Components/Screens/Diet/DietAttendncecheck';
import LayoutAdmin from './Components/LayoutAdmin/LayoutAdmin';
import AdminDashboard from './Components/Screens/Admin/AdminDashboard';
import MasterType from './Components/Screens/Admin/MasterType';
import Studentlist from './Components/Screens/School/StudentList';
import Test from './Components/Screens/Admin/Test';
import SchoolAttendencellist from './Components/Screens/Diet/SchoolAttendence';
import CheckAtendenceschool from './Components/Screens/Diet/CheckAtendenceschool';
import District from './Components/Screens/Admin/District';
import AdminSchool from './Components/Screens/Admin/AdminSchool';
import CheckDietAttendnce from './Components/Screens/Admin/CheckDietAttendnce';
import DietDistrictlist from './Components/Screens/Diet/DietDistrictlist';
import CollageStudent from './Components/Screens/Diet/CollageStudent';
import ReRunDistrict from './Components/Screens/Admin/ReRunDistrict';
import DistrictCollagelist from './Components/Screens/Admin/DistrictCollagelist';
import ReRunStudentlist from './Components/Screens/Admin/ReRunStudentlist';
import AdminPermission from './Components/Screens/Admin/AdminPermission';
import StaffRegistration from './Components/Screens/Admin/StaffRegistration';
import LayoutStaff from './Components/LayoutStaff/LayoutStaff';
import StaffDashboard from './Components/Screens/Staff/StaffDashboard';
import CollageDetail from './Components/Screens/Staff/CollageDetail';
import CollageList from './Components/Screens/Staff/CollageList';
import CollageStudentList from './Components/Screens/Staff/CollageStudentList';
import StudentForm from './Components/Screens/Staff/StudentForm';
import ReRunStudent from './Components/Screens/Admin/ReRunStudent';
import StudentListbyRollNo from './Components/Screens/Staff/StudentListbyRollNo';
import PasswordChnage from './Components/Screens/Diet/PasswordChnage';
import SchoolListDetail from './Components/Screens/Diet/SchoolListDetail';
import SchoolPaswordChange from './Components/Screens/School/SchoolPaswordChange';
import NewSchoolInsert from './Components/Screens/Diet/NewSchoolInsert';
import UpdateSchool from './Components/Screens/Diet/UpdateSchool';
import OrientationList from './Components/Screens/Diet/OrientationList';
import Schoolpasswordupdate from './Components/Screens/Diet/Schoolpasswordupdate';
import CollageListorientation from './Components/Screens/Diet/CollageListorientation';
import AssessmentList from './Components/Screens/Diet/AssessmentList';
import CollageListAssessment from './Components/Screens/Diet/CollageListAssessment';
import StudentlistChoice from './Components/Screens/Diet/StudentlistChoice';
import CollageListchoice from './Components/Screens/Diet/CollageListchoice';
import DietSchoolDetails from './Components/Screens/Diet/DietSchoolDetails';
import ChangeAllotment from './Components/Screens/Admin/ChangeAllotment';
import Mismatchattendence from './Components/Screens/Admin/Mismatchattendence';
import InsertDietSchool from './Components/Screens/Diet/InsertDietSchool';
import ListDietSchool from './Components/Screens/Diet/ListDietSchool';
import UpdateDietSchool from './Components/Screens/Diet/UpdateDietSchool';
import SearchAllSchool from './Components/Screens/Diet/SearchAllSchool';
import ChangeAllotments from './Components/Screens/Diet/ChangeAllotments';


function App() {
  const context = useContext(Provider);

  if (context.loading) {
    return <div>Loading...</div>
  }


  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/school' element={<Layout />}>
          <Route index element={< SchoolDashboard />} />
          <Route path='/school/StudentList' element={<SchoolStudentlist />} />
          <Route path='/school/Student' element={<Studentlist />} />
          <Route path='/school/SchoolPaswordChange' element={<SchoolPaswordChange />} />

          <Route path='/school/SchoolStudentAttendnce' element={<SchoolStudentAttendence />} />

        </Route>

        <Route path='/diet' element={<LayoutDiet />}>

          <Route index element={< DietDashboard />} />
          <Route path='/diet/SchoolList' element={<Schoollist />} />
          <Route path='/diet/OrientationList/:id' element={<OrientationList/>} />
          <Route path='/diet/AssessmentList/:id' element={<AssessmentList/>} />
          <Route path='/diet/CollageListorientation' element={<CollageListorientation/>} />
          <Route path='/diet/CollageListAssessment' element={<CollageListAssessment/>} />
          <Route path='/diet/NewSchoolInsert' element={<NewSchoolInsert />} />
          <Route path='/diet/ChangeAllotments' element={<ChangeAllotments />} />
          <Route path='/diet/SchoolListDetail' element={<SchoolListDetail />} />
          <Route path='/diet/PasswordChnage' element={<PasswordChnage />} />
          <Route path='/diet/SchoolAttendencellist' element={<SchoolAttendencellist />} />
          <Route path='/diet/DietDistrictlist' element={<DietDistrictlist />} />
          <Route path='/diet/CheckAtendenceschool/:id' element={<CheckAtendenceschool />} />

          <Route path='/diet/SchoolStudents/:id' element={<DietSchoolStudent />} />
          <Route path='/diet/DietAttendncecheck/:id' element={<DietAttendncecheck />} />
          {/* <Route path='/diet/SchoolPaswordChange/:id' element={<SchoolPaswordChange />} /> */}
          <Route path='/diet/UpdateSchool/:id' element={<UpdateSchool />} />
          <Route path='/diet/Schoolpasswordupdate/:id' element={<Schoolpasswordupdate />} />
          <Route path='/diet/CollageStudent/:id/:Classid' element={<CollageStudent />} />
          <Route path='/diet/StudentlistChoice/:id/:typeid' element={<StudentlistChoice />} />
          <Route path='/diet/CollageListchoice' element={<CollageListchoice/>} />
          <Route path='/diet/DietSchoolDetails' element={<DietSchoolDetails/>} />
          {/* ------------school insert list update Start-------------------------- */}
          <Route path='/diet/InsertDietSchool' element={<InsertDietSchool/>} />
          <Route path='/diet/ListDietSchool' element={<ListDietSchool/>} />
          <Route path='/diet/UpdateDietSchool/:id' element={<UpdateDietSchool/>} />
          <Route path='/diet/SearchAllSchool' element={<SearchAllSchool />} />
          {/* ------------school insert list update End-------------------------- */}

        </Route>
        <Route path='/admin' element={<LayoutAdmin />}>

          <Route index element={<AdminDashboard />} />
          <Route path='/admin/MasterType' element={<MasterType />} />
          <Route path='/admin/Mismatchattendence' element={<Mismatchattendence />} />
          <Route path='/admin/District' element={<District />} />
          <Route path='/admin/ChangeAllotment' element={<ChangeAllotment />} />
          <Route path='/admin/ReRunDistrict' element={<ReRunDistrict />} />
          <Route path='/admin/StaffRegistration' element={<StaffRegistration />} />
          <Route path='/admin/ReRunStudent' element={<ReRunStudent />} />
          <Route path='/admin/AdminSchool/:id' element={<AdminSchool />} />
          <Route path='/admin/AdminPermission/:id' element={<AdminPermission />} />
          <Route path='/admin/DistrictCollagelist/:id' element={<DistrictCollagelist />} />
          <Route path='/admin/CheckDietAttendnce/:id/:typeid' element={<CheckDietAttendnce />} />
          <Route path='/admin/ReRunStudentlist/:id/:Studenttype' element={<ReRunStudentlist />} />


        </Route>
      {/* ------------------------ staff pannel---------------------- */}
        <Route path='/Staff' element={<LayoutStaff />}>
          <Route index element={< StaffDashboard />} />
          <Route path='/Staff/CollageDetail' element={<CollageDetail />} />
          <Route path='/Staff/StudentForm/:uentityId' element={<StudentForm />} />
          <Route path='/Staff/CollageList/:id' element={<CollageList />} />
          <Route path='/Staff/CollageStudentList/:id/:classid/:Studenttype' element={<CollageStudentList />} />
          <Route path='/Staff/StudentListbyRollNo' element={<StudentListbyRollNo />} />



        </Route>


      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
