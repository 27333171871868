import React, { useState, useEffect, useContext } from 'react'
//import { Provider } from '../../../Context/Appcontext';
import { toast } from 'react-toastify';
import ExportApis from '../../../API/ExportApis';
import { Link } from 'react-router-dom'
import { Provider } from '../../../Context/Appcontext'; 

export default function ListDietSchool({ ...props }) {
 // const context = useContext(Provider);
  const [SchoolList, setSchooldata] = useState(null);
  const [BlockData, setBlockData] = useState([]);
  const [District, setDistrict] = useState([]);
  const context = useContext(Provider);
  useEffect(() => {
    handleSchooldata(context.user);
    Blocklist();
    handleDistrict();
  }, []);

  const handleSchooldata = (Id) => {
    console.log("Student Location........===>", Id);
    ExportApis.GetDistrictSchooldiet(Id).then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
         setSchooldata(Data);

        console.log("Student  List........===>", Data);
        // console.log('hi sunny',context.UserDetail.locationId)
      }
    });
  };
  const Blocklist = () => {
    ExportApis.Block(context.user)
      .then((resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setBlockData(Data);

          // console.log("Student  List........===>", Data);
          // console.log('hi sunny',context.UserDetail.locationId)
        }
      });
  };


  // District Dropdown
  const handleDistrict = () => {

    ExportApis.GetDistrict().then((resp) => {
      if (resp.ok) {
        let Data = resp.data;
        setDistrict(resp.data);
        console.log("district1111 ", resp.data)

      }
    });
  };


  const changeBlock = (id) => {
    console.log('District_Id...........', id)
    ExportApis.Block(id)
      .then((resp) => {
        if (resp.ok) {
          let Data = resp.data;
          setBlockData(Data);
          // console.log("Student  List........===>", Data);
          // console.log('hi sunny',context.UserDetail.locationId)
        }
      });
  }

  return (
    <>
      <div className="page-section">
        <h1 className="text-display-1">District School List</h1>
      </div>

    
        <div className="panel panel-default paper-shadow" data-z="0.5">
          <div className="panel-heading">
            <div className=" form-group daterangepicker-report" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              
             
              {/* <div className=" max-width-300">
                                    <input type="date" className="form-control" onChange={handledate}
                                        required
                                        min="2022-04-13"
                                        max={current} />
                                </div> */}

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="blockId">District</label>
                <select
                  className='form-control input-default'
                  onChange={(e) => changeBlock(e.target.value)}
                  disabled
                  value={context.user}
                >
                  <option selected>Open this select District</option>
                  {District?.map((item) => (
                    <>
                      <option value={item.id}>{item.name}</option>
                    </>
                  ))}
                </select>

              </div>

            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="blockId">Block</label>
                <select
                  name='blockId'
                  id='blockId'
                  onChange={(e) => handleSchooldata(e.target.value)}
                  className='form-control input-default'  
                >
                  <option value={''}> Select Block</option>
                  {BlockData.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
                
              </div>
            </div>
            </div>
          </div>
          {SchoolList != null ? SchoolList.length > 0 ? (
          <div className="table-responsive">
            <table className="table table-hover table-outline  mb-0 ">
              <thead >
                <tr>
                  <th className="text-center">SR No</th>
                  <th className="text-center">Code</th>
                  <th className="text-center">School Name</th>
                  <th className="text-center">Address</th>
                  <th className="text-center">UserName </th>
                  <th className="text-center">Password</th>
                  <th className="text-center">Update School</th>
                  {/* <th className="text-center">Delete School</th> */}

                </tr>
              </thead>
              <tbody>
                {SchoolList.map((data, i) => (
                  <tr key={data.uentityId}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{data.code}</td>
                    <td className="text-center"><strong>{data.schoolName}</strong></td>
                    {/* <td className="text-center"><strong>{data.mobileNo}</strong></td> */}
                    <td className="text-center">{data.locationName}</td>
                    <td className="text-center">{data.loginCode}</td>
                    <td className="text-center">{data.loginPassword}</td>
                     <td className="text-center">
                       
                      <Link to={`/diet/UpdateDietSchool/${data.uentityId}`}>  <button type="button" className="btn btn-success">Update School </button></Link>
                    </td>  

                      {/* <td className="text-center">
                        <Link to={`/Schoolpasswordupdate/${data.uentityId}`}>  <button type="button" className="btn btn-success">Update Details</button></Link>
                      </td> */}
                    {/* <td className="text-center">
                      <Link className="btn btn-danger" to={`/diet/UpdateSchool/${data.uentityId}` }>Delete School</Link>
                    </td> */}
                    {/* <td 
                    <td className="text-center">
                      <Link to={`/diet/SchoolPaswordChange/${data.uentityId}`}>  <button type="button" className="btn btn-success">Change Password</button></Link>
                    </td>
                    {/* <td className="text-center">
                      <Link to={`/diet/DietAttendncecheck/${data.uentityId}`}>  <button type="button" className="btn btn-success">Check Attendance</button></Link>
                    </td> */}





                  </tr>
                ))}

              </tbody>
            </table>

          </div>
                ) : <center><h3>School Not Available</h3></center> //<BeatLoader color="blue" loading />
                :
        
                <div className='d-flex justify-content-center align-items-center'>
                  <button class="btn btn-primary" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </div>
        
              }
        </div>

    </>
  )
}

